import * as Helpers from "./problems-helpers";
import ProblemCell from "./ProblemCell.vue";
import IconRefresh from "@/icons/arrow-rotate-right-solid.svg";

// @ts-ignore
const TimeModal = () => import(/* webpackChunkName: "timemodal" */ "@/views/time-modal/TimeModal.vue");

export default {
    name: "Problems",
    components: {
        TimeModal,
        ProblemCell,
        IconRefresh,
    },
    props: ["type"],
    data() {
        return {
            problems: [],
            filter: '',
            timeEntryId: null,
        };
    },
    computed: {
        config() {
            return Helpers.mapping[this.type];
        },
        ignorable() {
            return !!this.config.ignorable;
        },
    },
    methods: {
        refresh() {
            const params = {
                type: this.type,
                filter: this.filter,
            };
            this.$store.dispatch("Dashboard/list/getProblems", params).then(problems => {
                this.problems = problems;
            });
        },
        ignore(id) {
            if (!window.confirm('Êtes-vous sûr de vouloir ignorer cette ligne de crédit ?')) return;
            this.$store.dispatch("Credit/list/ignore", id).then(() => {
                this.refresh();
            });
        },
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.refresh();
        });
    },
};
