<style lang="scss">
@import "~tippy.js/dist/tippy.css";
</style>

<template>
  <td :class="{ 'text-right': config.type === 'number' }">
    <span
      ref="value"
      @click="gotoLink"
      :class="[
        'text-nowrap',
        'd-flex',
        'align-items-center',
        { text_link: !!config.link, abbr: hasTooltip },
      ]"
    >
      <IconCalendar class="mr-1" v-if="config.link === 'timeEntry'" />
      {{ formattedValue }}
    </span>
  </td>
</template>

<script>
import tippy from "tippy.js";
import IconCalendar from "@/icons/calendar-solid.svg";

export default {
  name: "ProblemCell",
  components: { IconCalendar },
  props: ["value", "config", "problemId"],
  computed: {
    formattedValue() {
      if (this.config.type === "number") {
        const numVal = parseFloat(this.value);
        if (!numVal) return "\xa0";
        return Math.round(numVal * 100) / 100;
      }
      return this.value;
    },
    hasTooltip() {
      return this.config.tooltip && this.config.tooltip[this.value];
    },
  },
  methods: {
    gotoLink() {
      if (this.config.link === "issue") {
        return this.$router.push({
          name: "tree",
          params: { type: "issue", id: this.problemId },
        });
      }
      if (this.config.link === "timeEntry") {
        this.$emit("openEntry", this.problemId);
      }
    },
  },
  mounted() {
    if (this.hasTooltip) {
      tippy(this.$refs.value, {
        content: this.config.tooltip[this.value],
      });
    }
  },
};
</script>
